import { CommentOutlined, ContainerOutlined, EditOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { routes } from 'api/routes';

import { Box } from 'components/box';
import { Container } from 'components/container';
import { ErrorBoundary } from 'components/error-boundary';
import { Header } from 'components/header';
import { Sidebar } from 'components/sidebar';

import { Project } from 'models/project';

import styles from './project-layout.module.scss';

export interface ProjectLayoutProps {
  className?: string;
  projectId: Project['id'];
  headerSlot?: ReactNode;
  containerSlot?: ReactNode;
  children: ReactNode;
}

export function ProjectLayout({ className, projectId, headerSlot, containerSlot, children }: ProjectLayoutProps) {
  const classes = cn(styles.projectLayout, className);

  return (
    <div className={classes}>
      <Sidebar
        className={styles.sidebar}
        menuItems={[
          {
            label: 'Описание исследования',
            to: routes.project(projectId),
            icon: <EditOutlined />,
          },
          {
            label: 'Интервью',
            to: routes.interviews(projectId),
            icon: <CommentOutlined />,
            end: false,
            onboardingSelector: 'sidebar-menu-interview',
          },
          {
            label: 'Отчет',
            to: routes.report(projectId),
            icon: <ContainerOutlined />,
            onboardingSelector: 'sidebar-menu-report',
          },
        ]}
      />
      <Header className={styles.header} headerSlot={headerSlot} />
      <Container className={styles.container}>
        <Box onboardingSelector='project-box' className={cn(styles.box, { [styles.box_auto]: containerSlot })}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
        {containerSlot && (
          <Box onboardingSelector='project-additional-box' className={styles.additionalBox}>
            <ErrorBoundary>{containerSlot}</ErrorBoundary>
          </Box>
        )}
      </Container>
    </div>
  );
}

