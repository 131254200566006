import debounce from 'lodash.debounce';
import React from 'react';
import { Descendant } from 'slate';

import { Editor } from 'components/editor';
import { CustomElement } from 'components/editor/editor.types';
import { PlayerCurrentTime } from 'components/player';

import { Interview } from 'models/interview';
import { Question } from 'models/question';
import { Transcription as ITranscription, TranscriptionChannel } from 'models/transcription';

import { nodesToTranscriptionTexts, transcriptionToNodes } from './intreview.utils';

interface InterviewAdapterProps {
  interviewItemKey: number;
  onReplicaPlayClick?: (currentTime: PlayerCurrentTime) => void;
  currentTime?: PlayerCurrentTime;
  interview: Interview;
  onChange: (transcription: ITranscription, channels?: TranscriptionChannel[]) => void;
  onRemove: (transcription: ITranscription) => void;
  onDownload: (transcription: ITranscription) => void;
  isDownloading: boolean;
  onPay: (transcription: ITranscription, callback: () => void) => void;
  isPaying: boolean;
  onChangeChannels: (channels: TranscriptionChannel[]) => void;
  questionsList: Question[];
  activeChannelIds: Set<TranscriptionChannel['id']>;
  onUpdateTranscriptionStatusAndType: (
    status: ITranscription['transcribeStatus'],
    type: ITranscription['type'],
  ) => void;
}

export function InterviewAdapter(props: InterviewAdapterProps) {
  const initialNodes = transcriptionToNodes(props.interview);

  const onChange = (updatedValue: Descendant[]) => {
    const customElement = updatedValue?.[0] as CustomElement;
    if (customElement?.type === 'interview-wrap') {
      const texts = nodesToTranscriptionTexts(updatedValue);
      const updatedTranscription = customElement.meta?.transcription || props.interview.transcriptions[0];
      props.onChange({
        ...updatedTranscription,
        texts,
      });
    }
  };

  const debouncedChange = debounce(onChange, 500);

  return (
    <Editor
      currentTime={props.currentTime}
      onReplicaPlayClick={props.onReplicaPlayClick}
      interviewItemKey={props.interviewItemKey}
      initialNodes={initialNodes}
      onChange={() => null}
      onChangeNodes={debouncedChange}
      fileType='interviews'
      fileId={props.interview.id}
      isInterview
      questionsList={props.questionsList}
      channels={props.interview.channels}
      onChangeChannels={props.onChangeChannels}
      onRemoveTranscription={props.onRemove}
      onDownloadTranscription={props.onDownload}
      isDownloadingTranscription={props.isDownloading}
      onPayTranscription={props.onPay}
      isPayingTranscription={props.isPaying}
      readOnly={props.interview?.transcriptions?.[0]?.transcribeStatus !== 'done'}
      activeChannelIds={props.activeChannelIds}
      onUpdateTranscriptionStatusAndType={props.onUpdateTranscriptionStatusAndType}
    />
  );
}

